import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./components/GoogleAnalytics";

import "./styles.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { redirect } from "react-router-dom";
import ChatScreenWindow from "./components/ChatScreenWindow";

import { InputTextProps } from "./components/SendMessage";
import WelcomeScreen from "./screens/WelcomeScreen";
import Signup from "./components/Signup";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import { useAuth } from "./components/AuthContext";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import GoogleAnalyticsProvider from "./provider/GoogleAnalyticsProvider";

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
    _fbq?: (...args: any[]) => void;
  }
}

export const MetaPixel = () => {
  return (
    <Helmet>
      <script id="facebook-pixel-script">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '879923630994362');
        fbq('track', 'PageView');`}
      </script>
      <noscript id="facebook-pixel-image">
        {`<img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=879923630994362&ev=PageView&noscript=1"
        />`}
      </noscript>
    </Helmet>
  );
};

const App: React.FC<InputTextProps> = ({
  selectedModel,
  selectedCreator,
  setSelectedModel,
  selectedChatId,
  chats,
  updateChatMessages,
}) => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;

  //ONLY FOR PRODUCTION CODES ONLY*/

  // useEffect(() => {
  //   // Load Google Analytics script
  //   const loadGoogleAnalytics = () => {
  //     const script = document.createElement("script");
  //     script.src = "https://www.googletagmanager.com/gtag/js?id=G-7SP0CPDVHQ";
  //     script.async = true;
  //     document.head.appendChild(script);

  //     script.onload = () => {
  //       (window as any).dataLayer = (window as any).dataLayer || [];
  //       (window as any).gtag = function gtag(...args: any[]) {
  //         (window as any).dataLayer.push(args);
  //       };

  //       (window as any).gtag("js", new Date());
  //       (window as any).gtag("config", "G-7SP0CPDVHQ");
  //     };
  //   };

  //   // Call both loaders
  //   loadGoogleAnalytics();
  //   MetaPixel();

  //   return () => {
  //     // Cleanup dynamically added scripts
  //     const gaScript = document.querySelector(
  //       'script[src="https://www.googletagmanager.com/gtag/js?id=G-7SP0CPDVHQ"]'
  //     );
  //     if (gaScript) document.head.removeChild(gaScript);

  //     const fbScript = document.querySelector(
  //       'script[src="https://connect.facebook.net/en_US/fbevents.js"]'
  //     );
  //     if (fbScript) document.head.removeChild(fbScript);
  //   };
  // }, []);

  return (
    <>
      <MetaPixel />
      <Router>
        <GoogleAnalyticsProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/welcome" replace />} />
            <Route path="/welcome" element={<WelcomeScreen />} />

            <Route
              path="/chat"
              element={
                <ChatScreenWindow
                  selectedChatId={selectedChatId}
                  chats={chats}
                  updateChatMessages={updateChatMessages}
                  selectedCreator={selectedCreator}
                  selectedModel={selectedModel}
                  setSelectedModel={setSelectedModel}
                />
              }
            />

            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>{" "}
        </GoogleAnalyticsProvider>
      </Router>
    </>
  );
};

export default App;
