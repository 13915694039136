import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { useAuth } from "../components/AuthContext";
import axios from "axios";
import * as Dialog from "@radix-ui/react-dialog";
import MyBilling from "../components/MyBilling";
import crossicon from "src/assets/cross-2.svg";
import { CustomTopUp } from "../components/SubscriptionPanel";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
console.log("stripePromise", typeof stripePromise);
// import { useSession } from "../components/SessionProvider";
// import "../chatwindow.css";

interface navProps {
  data?: any;
  loading?: boolean;
}

const NavigationBar: React.FC<navProps> = ({ data, loading = true }) => {
  // const userSession = useSession() || {};
  const { session } = useAuth();
  // console.log("credts_data_in_nav", data);
  const user_id = session?.user?.user_metadata.user_id;
  const navigate = useNavigate();
  const [openModalPromo, setOpenModalPromo] = useState(false);
  const [userSubstype, setUserSubstype] = useState<number>(0);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [currentCreditsLifetime, setCurrentCreditsLifetime] = useState(() => {
    if (data) {
      return data.boughtLifetime.total_credits;
    } else {
      return 0;
    }
  });
  const [currentCreditsMonthly, setCurrentCreditsMonthly] = useState(() => {
    if (data) {
      return data.totalExpiryCredits;
    } else {
      return 0;
    }
  });

  const location = useLocation();
  const path = location.pathname; // Get the current path (e.g., "/login")
  //console.log("path", path);

  // Check if the path is "/login"

  useEffect(() => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    const checkIfNewUserAndSubscriptionType = async () => {
      try {
        const response = await axios.get(
          `${urlEnv}checkIfNewUserAndSubscriptionType`,
          {
            params: { user_id: user_id },
          }
        );
        console.log("checkifnewuser", response.data);

        if (
          response?.data?.subscription &&
          response?.data?.subscription !== 0
        ) {
          setUserSubstype(response?.data?.subscription);
          console.log("set user subs type", response?.data?.subscription);
        } else {
          // setUserSubstype("");
        }

        if (response?.data?.success) {
          setIsNewUser(true);

          // console.log("set user role type");
        } else {
          setIsNewUser(false);
          // console.log("yayaya");
        }
      } catch (err) {
        console.error("error fething checkIfNewUser:", err.message);
      }
    };
    checkIfNewUserAndSubscriptionType();
  }, [openModalPromo]);

  // const fetchData = async () => {
  //   setLoading(true);
  //   const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  //   try {
  //     const response = await axios.post(`${urlEnv}fetch-credits-history`, {
  //       user_id: user_id,
  //     });
  //     console.log("responseasdasdsad", response.data);
  //     setData(response.data);
  //   } catch (err) {
  //     console.log(err.message || "An error occurred");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [user_id]);

  // useEffect(() => {
  //   console.log("autodata", data);
  // }, [data]);

  const toWelcomeScreen = () => {
    window.location.href = "/welcome";
  };
  return (
    <nav className="nav-height">
      <div className="flex items-center">
        <img
          src={logo}
          alt="Logo"
          className="h-8 w-8 image-nav"
          onClick={toWelcomeScreen}
        />
      </div>
      <div className="flex space-x-4">
        {/* <a href="#" className="hover:underline">
          Blog
        </a>
        <a href="#" className="hover:underline">
          Community
        </a>
        <a href="#" className="hover:underline">
          Research
        </a>
        <a href="#" className="hover:underline">
          Contact Us
        </a> */}
        {path.toLowerCase().includes("login") ||
        path.toLowerCase().includes("signup")
          ? ""
          : session &&
            user_id && (
              <>
                <div className="credit-container gap-5">
                  <div className="flex font-12px gap-10">
                    <div className="credit-box">
                      {data?.boughtLifetime
                        ? parseFloat(
                            data.boughtLifetime.total_credits.toFixed(4)
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 0, // Allow minimum fraction digits to be 0 after trimming
                            maximumFractionDigits: 4, // But keep a maximum of 4 fractional digits
                          })
                        : 0}{" "}
                      Lifetime Credits
                    </div>

                    <Dialog.Root>
                      <Dialog.Trigger asChild>
                        <button className="credit-button">Buy More</button>
                      </Dialog.Trigger>

                      <Dialog.Portal>
                        <div className="sideBar-subs-panel">
                          <div className="overlay"></div>
                          <Dialog.Overlay className="DialogOverlay" />
                          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
                            <Dialog.Title className="DialogTitle"></Dialog.Title>
                            <Dialog.Description className="DialogDescription"></Dialog.Description>

                            <CustomTopUp />

                            <div
                              style={{
                                display: "flex",
                                marginTop: 25,
                                justifyContent: "flex-end",
                              }}
                            >
                              <Dialog.Close asChild></Dialog.Close>
                            </div>
                            <Dialog.Close asChild>
                              <button className="IconButton" aria-label="Close">
                                <img
                                  src={crossicon}
                                  alt="icon"
                                  style={{
                                    width: "20px",
                                    height: "20px",

                                    filter: "invert(1)",
                                  }}
                                />
                              </button>
                            </Dialog.Close>
                          </Dialog.Content>
                        </div>
                      </Dialog.Portal>
                    </Dialog.Root>
                  </div>
                  <div className="flex font-12px gap-10">
                    <div className="credit-box">
                      {data?.totalExpiryCredits
                        ? parseFloat(
                            data.totalExpiryCredits.toFixed(4)
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 0, // Allow minimum fraction digits to be 0 after trimming
                            maximumFractionDigits: 4, // But keep a maximum of 4 fractional digits
                          })
                        : 0}{" "}
                      {data?.monthlyCredits && "Monthly Credits"}
                      {data?.yearlyCredits && "Yearly Credits"}
                      {!data?.yearlyCredits && !data?.monthlyCredits
                        ? "Credits"
                        : ""}
                    </div>

                    <Dialog.Root
                      open={openModalPromo}
                      onOpenChange={setOpenModalPromo}
                    >
                      <Dialog.Trigger asChild>
                        <button className="credit-button">Manage</button>
                      </Dialog.Trigger>

                      <Dialog.Portal>
                        <div className="sideBar-subs-panel">
                          <div className="overlay"></div>
                          <Dialog.Overlay className="DialogOverlay" />
                          <Dialog.Content className="DialogContent DialogCustomWidth">
                            <Dialog.Title className="DialogTitle"></Dialog.Title>
                            <Dialog.Description className="DialogDescription"></Dialog.Description>
                            <Elements stripe={stripePromise}>
                              <MyBilling
                                data={data}
                                loading={loading}
                                // openModalPromo={openModalPromo}
                                // setOpenModalPromo={setOpenModalPromo}
                                //isNewUser={isNewUser}
                                userSubstype={userSubstype}
                              />
                            </Elements>

                            <div
                              style={{
                                display: "flex",
                                marginTop: 25,
                                justifyContent: "flex-end",
                              }}
                            >
                              <Dialog.Close asChild></Dialog.Close>
                            </div>
                            <Dialog.Close asChild>
                              <button className="IconButton" aria-label="Close">
                                <img
                                  src={crossicon}
                                  alt="icon"
                                  style={{
                                    width: "20px",
                                    height: "20px",

                                    filter: "invert(1)",
                                  }}
                                />
                              </button>
                            </Dialog.Close>
                          </Dialog.Content>
                        </div>
                      </Dialog.Portal>
                    </Dialog.Root>
                  </div>
                </div>
              </>
            )}

        {/* {loading && !session && (
          <>
            {" "}
            <a href="/Signup" className="hover:underline">
              Signup
            </a>{" "}
            <a href="/login" className="hover:underline">
              Login
            </a>
          </>
        )} */}
      </div>
    </nav>
  );
};

export default NavigationBar;
