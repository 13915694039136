import React, { useState, useEffect, useCallback, useRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import { Button } from "react-day-picker";

import { useNavigate } from "react-router-dom";
import Dropdownllm from "../components/Llm";
import InputText from "../components/SendMessage";
import { Message } from "../components/SendMessage";
import { ChatHistory } from "../components/SendMessage";
import { InputTextProps } from "../components/SendMessage";
import settingImage from "src/assets/settings.png";
import Sidebar, { Image } from "../components/SideBar";
import SuggestedTopics from "./SuggestedTopics";
import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import "src/modal.css";
import SettingsPanel from "src/components/SettingsPanel";
import NavigationBar from "./Navigationbar";
import { useAppContext } from "../components/LLMContext";
import axios from "axios";
import { useAuth } from "../components/AuthContext";

const WelcomeSection: React.FC<InputTextProps> = ({ onOpenSettings }) => {
  const { session, setSession } = useAuth();
  const user_id = session?.user?.user_metadata.user_id;
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();

  //this will save the chats to local/ using cache
  const savedChats = localStorage.getItem("chats");
  const [chats, setChats] = useState<ChatHistory[]>(() => {
    return savedChats ? JSON.parse(savedChats) : [];
  });
  useEffect(() => {
    localStorage.setItem("chats", JSON.stringify(chats));
  }, [chats]);

  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const updateChatMessages = (chatId: number, messages: Message[]) => {
    const updatedChats = chats.map((chat) => {
      if (chat.id === chatId) {
        return { ...chat, messages };
      }
      return chat;
    });
    console.log("length:7 ", chats.length);
    setChats(updatedChats);
  };

  const [userChatData, setUserChatData] = useState<[]>(null);
  const [loadingChatData, setLoadingChatData] = useState(true);

  const fetchUserChatData = async () => {
    setLoadingChatData(true);
    // console.log("calling_fetchUserChatData: ", selectedChatId);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;

    try {
      const response = await axios.get(`${urlEnv}fetch-user-chats`, {
        params: { user_id: user_id, selectedChatId },
      });
      if (response.data) {
        setUserChatData(response.data);
        console.log("responsedata123", response.data);
        localStorage.setItem("chats", JSON.stringify(response.data));
        setChats(response.data);
      }
      setLoadingChatData(false);
    } catch (error) {
      console.error("error fetching userdata:", error);
      setLoadingChatData(false);
    }
    setLoadingChatData(false);
  };

  const hasfetchUserChatData = useRef(false);
  useEffect(() => {
    if (user_id && !hasfetchUserChatData.current) {
      console.log("hajksdhkjasdh");
      hasfetchUserChatData.current = true;
      fetchUserChatData();
    }
  }, []);

  //automatic add a chat if chat array is empty
  const handleNewChat = useCallback(() => {
    // Find the maximum existing ID
    const maxId = chats.reduce((max, chat) => Math.max(max, chat.id), -0);
    const newChat: ChatHistory = {
      // thread_id: 263,
      id: maxId + 1,
      title: `Chat ${maxId + 1}`,
      messages: [],
      creator: selectedCreator,
      model: selectedModel,
      systemInstructions: systemInstructions,
      temperature: temperature,
      maxTokens: maxTokens,
      stopSequences: stopSequences,
      topP: topP,
      frequencyPenalty: frequencyPenalty,
      presencePenalty: presencePenalty,
    };
    setChats((prevChats) => [...prevChats, newChat]);
    setSelectedChatId(newChat.id);
  }, [chats.length]);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/chat", { state: { fromWelcomeScreen: true } });
  };
  // const [inputText, setInputText] = useState("");
  const [inputText, setInputText] = useState<string>(() => {
    const savedInputText = sessionStorage.getItem("inputText");
    return savedInputText || "";
  });

  useEffect(() => {
    sessionStorage.setItem("inputText", inputText);
  }, [inputText]);

  const [isLoading, setIsLoading] = useState(false);

  // const handleSettingsChange = (newSettings: any) => {
  //   setSettings(newSettings);
  // };

  //for advance settings

  const [systemInstructions, setSystemInstructions] = useState<string>(() => {
    const savedSystemInstructions =
      sessionStorage.getItem("systemInstructions");
    return savedSystemInstructions || "";
  });
  const [temperature, setTemperature] = useState<number>(() => {
    const savedTemperature = sessionStorage.getItem("temperature");
    return savedTemperature ? parseFloat(savedTemperature) : 1;
  });
  const [maxTokens, setMaxTokens] = useState<number>(() => {
    const savedMaxTokens = sessionStorage.getItem("maxTokens");
    return savedMaxTokens !== null ? parseInt(savedMaxTokens) : 100;
  });

  const [topP, setTopP] = useState<number>(() => {
    const savedTopP = sessionStorage.getItem("topP");
    return savedTopP !== null ? parseFloat(savedTopP) : 1;
  });

  const [stopSequences, setStopSequences] = useState<string[]>(() => {
    const savedStopSequences = sessionStorage.getItem("stopSequences");
    return savedStopSequences ? JSON.parse(savedStopSequences) : [];
  });

  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(() => {
    const savedFrequencyPenalty = sessionStorage.getItem("frequencyPenalty");
    return savedFrequencyPenalty !== null
      ? parseFloat(savedFrequencyPenalty)
      : 0;
  });

  const [presencePenalty, setPresencePenalty] = useState<number>(() => {
    const savedPresencePenalty = sessionStorage.getItem("presencePenalty");
    return savedPresencePenalty !== null ? parseFloat(savedPresencePenalty) : 0;
  });

  useEffect(() => {
    sessionStorage.setItem("systemInstructions", systemInstructions);
  }, [systemInstructions]);

  useEffect(() => {
    sessionStorage.setItem("temperature", temperature.toString());
  }, [temperature]);

  useEffect(() => {
    sessionStorage.setItem("maxTokens", maxTokens.toString());
  }, [maxTokens]);

  useEffect(() => {
    sessionStorage.setItem("topP", topP.toString());
  }, [topP]);

  useEffect(() => {
    if (stopSequences.length > 0) {
      sessionStorage.setItem("stopSequences", JSON.stringify(stopSequences));
    }
  }, [stopSequences]);

  useEffect(() => {
    sessionStorage.setItem("frequencyPenalty", frequencyPenalty.toString());
  }, [frequencyPenalty]);

  useEffect(() => {
    sessionStorage.setItem("presencePenalty", presencePenalty.toString());
  }, [presencePenalty]);

  //for llm

  useEffect(() => {
    sessionStorage.setItem("selectedModel", selectedModel);
  }, [selectedModel]);

  useEffect(() => {
    sessionStorage.setItem("selectedCreator", selectedCreator);
  }, [selectedCreator]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("hasSentInitialMessage");
    };
  }, []);

  return (
    <>
      <div className="welcome-section">
        <div className="welcome-section2">
          <h1>Welcome to Panels AI</h1>&nbsp;
          <img src={logo} alt="Logo" className="welcome-logo" />
        </div>
        <div className="search-bar">
          {/* <Dropdownllm
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            selectedCreator={selectedCreator}
            setSelectedCreator={setSelectedCreator}
            selectedLLM={selectedCreator + ": " + selectedModel}
            setSelectedLLM={setSelectedLLM}
          /> */}

          <InputText
            inputText={inputText}
            setInputText={setInputText}
            // isLoading={isLoading}
            // setIsLoading={setIsLoading}
            // sendMessage={sendMessage}
            selectedCreator={selectedCreator}
            selectedChatId={selectedChatId}
            chats={chats}
            updateChatMessages={updateChatMessages}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            startNewChat={handleNewChat}
            handleButtonClick={handleButtonClick}
            //
            systemInstructions={systemInstructions}
            setSystemInstructions={setSystemInstructions}
            temperature={temperature}
            setTemperature={setTemperature}
            maxTokens={maxTokens}
            setMaxTokens={setMaxTokens}
            topP={topP}
            setTopP={setTopP}
            stopSequences={stopSequences}
            setStopSequences={setStopSequences}
            frequencyPenalty={frequencyPenalty}
            setFrequencyPenalty={setFrequencyPenalty}
            presencePenalty={presencePenalty}
            setPresencePenalty={setPresencePenalty}
          />
        </div>
        <div className="advanced-settings">
          <hr />

          <a
            type="button"
            className="welcome-advance-settings"
            onClick={onOpenSettings}
          >
            <Image source={settingImage} alternate={"settingImage"} />
            Advanced Settings
          </a>
          <hr />
        </div>

        {/* <SuggestedTopics /> */}
      </div>
    </>
  );
};

export default WelcomeSection;
