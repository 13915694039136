import React, { useEffect, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import useGoogleAnalytics, { trackingId } from "../hooks/useGoogleAnalytics";

const GoogleAnalyticsProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { trackPageView } = useGoogleAnalytics();
  const location = useLocation();

  useEffect(() => {
    if (trackingId) {
      try {
        trackPageView(location.pathname + location.search);
      } catch (error) {
        console.log("Error executing trackPageView Google Analytics", {
          Error: error,
        });
      }
    }
  }, [location, trackPageView]);
  return <>{children}</>;
};

export default GoogleAnalyticsProvider;
