import React, { useState, useEffect, useRef, ForwardedRef } from "react";
import "../styles.css";
import logo from "../assets/logo.png";
import profpic from "../assets/image.jpeg";
import share from "../assets/share.png";
import more from "../assets/more.png";
import axios from "axios";
import { User } from "lucide-react";
import Dropdownllm from "./Llm";
import Anthropic from "@anthropic-ai/sdk";
import { useLocation, useNavigate } from "react-router-dom";
import SendButton from "./SendButton";
import { useAuth } from "./AuthContext";
import JellyOozeLoader from "./Loader";
import { encode } from "gpt-tokenizer";

import * as Dialog from "@radix-ui/react-dialog";
import crossicon from "src/assets/cross-2.svg";
import { useAppContext } from "./LLMContext";
import { stringify } from "querystring";

export interface Message {
  id: number;
  text: string;
  role: "system" | "assistant" | "user";
  isNew?: boolean;
}

export interface ChatHistory {
  // thread_id: number;
  id: number;
  title: string;
  messages: Message[];
  creator: string;
  model: string;
  systemInstructions: string;
  temperature: number;
  maxTokens: number;
  stopSequences: string[];
  //set to optional for Anthropic creator
  topP?: number;
  frequencyPenalty?: number;
  presencePenalty?: number;
}

export interface InputTextProps {
  inputText?: string;
  setInputText?: React.Dispatch<React.SetStateAction<string>>;
  // isLoading: boolean;
  // setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  // sendMessage: () => Promise<void>;
  selectedCreator?: string;

  selectedModel?: string;
  setSelectedModel?: React.Dispatch<React.SetStateAction<string>>;
  selectedChatId?: number | null;
  chats?: ChatHistory[];
  setChats?: React.Dispatch<React.SetStateAction<ChatHistory[]>>;
  updateChatMessages?: (
    chatId: number,
    messages: Message[],
    creator: string,
    model: string,
    systemInstructions: string,
    temperature: number,
    maxTokens: number,
    stopSequences: string[],
    //set to optional for Anthropic creator
    topP?: number,
    frequencyPenalty?: number,
    presencePenalty?: number
  ) => void;
  handleButtonClick?: () => void;
  startNewChat?: () => void;
  sendMessageButtonRef?: ForwardedRef<HTMLButtonElement>;
  onChatLoaded?: () => void;
  onOpenSettings?: () => void;
  disabled?: boolean;

  systemInstructions?: string;
  setSystemInstructions?: React.Dispatch<React.SetStateAction<string>>;
  temperature?: number;
  setTemperature?: React.Dispatch<React.SetStateAction<number>>;
  maxTokens?: number;
  setMaxTokens?: React.Dispatch<React.SetStateAction<number>>;
  topP?: number;
  setTopP?: React.Dispatch<React.SetStateAction<number>>;
  stopSequences?: string[];
  setStopSequences?: React.Dispatch<React.SetStateAction<string[]>>;
  frequencyPenalty?: number;
  setFrequencyPenalty?: React.Dispatch<React.SetStateAction<number>>;
  presencePenalty?: number;
  setPresencePenalty?: React.Dispatch<React.SetStateAction<number>>;

  withSystemTOF?: boolean;

  loadingChatData?: boolean;
}

const tokenLimits = [
  {
    model: "gpt-3.5-turbo-0125",
    limit: 16385,
  },
  {
    model: "gpt-4",
    limit: 8192,
  },
  {
    model: "gpt-4-turbo",
    limit: 128000,
  },
  {
    model: "gpt-4o-mini",
    limit: 128000,
  },
  {
    model: "gpt-4o",
    limit: 128000,
  },
  {
    model: "claude-3-5-sonnet-20240620",
    limit: 200000,
  },
  {
    model: "claude-3-opus-20240229",
    limit: 200000,
  },
  {
    model: "claude-3-haiku-20240307",
    limit: 200000,
  },
];

const InputText: React.FC<InputTextProps> = ({
  inputText,
  setInputText,
  // isLoading,
  // setIsLoading,
  // sendMessage,
  // selectedCreator,
  // selectedModel,
  // setSelectedModel,
  selectedChatId,
  chats,
  updateChatMessages,
  handleButtonClick,
  startNewChat = () => {},
  sendMessageButtonRef,

  systemInstructions,
  setSystemInstructions,
  temperature,
  setTemperature,
  maxTokens,
  setMaxTokens,
  topP,
  setTopP,
  stopSequences,
  setStopSequences,
  frequencyPenalty,
  setFrequencyPenalty,
  presencePenalty,
  setPresencePenalty,
}) => {
  const {
    selectedModel,
    setSelectedModel,
    selectedCreator,
    setSelectedCreator,
    selectedLLM,
    setSelectedLLM,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { session } = useAuth();

  const [isLimit, setIsLimit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const [isEmptyCredits, setIsEmptyCredits] = useState<boolean>(false);
  const [isSubscribe, setIsSubscribe] = useState<boolean>(false);
  const [loadingIsSubscribe, setLoadingIsSubscribe] = useState<boolean>(true);

  // if the user's credits is below 1024
  // const [remainingCredits, setRemainingCredits] = useState<number>(1025);

  const user_id = session?.user?.user_metadata.user_id;

  let model_id;
  switch (selectedModel) {
    // case "gpt-3.5-turbo":
    //   model_id = 5;
    //   break;
    case "gpt-4o":
      model_id = 2;
      break;
    case "gpt-4-turbo":
      model_id = 3;
      break;
    case "gpt-4":
      model_id = 4;
      break;
    case "claude-3-5-sonnet-20240620":
      model_id = 8;
      break;
    case "gpt-3.5-turbo-0125":
      model_id = 9;
      break;
    case "gpt-4o-mini":
      model_id = 11;
      break;
    case "claude-3-opus-20240229":
      model_id = 13;
      break;
    case "claude-3-haiku-20240307":
      model_id = 14;
      break;
    default:
      model_id = 9;
      break;
  }

  const handleSettingsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "inputText":
        setInputText && setInputText(value);
        break;
      default:
        break;
    }
  };

  // if (handleButtonClick != null) {
  //   handleButtonClick();
  //   //   //navigate
  //   console.log("created new chat");
  //   return;
  // }

  //navigate after render
  useEffect(() => {
    if (
      selectedChatId != null &&
      handleButtonClick != null &&
      inputText.trim() != ""
    ) {
      handleButtonClick(); //navigate to chats
    }
  }, [handleButtonClick, navigate]);

  const removeAllItems = () => {
    sessionStorage.removeItem("systemInstructions");
    sessionStorage.removeItem("temperature");
    sessionStorage.removeItem("maxTokens");
    sessionStorage.removeItem("topP");
    sessionStorage.removeItem("stopSequences");
    sessionStorage.removeItem("frequencyPenalty");
    sessionStorage.removeItem("presencePenalty");
    sessionStorage.removeItem("selectedModel");
    sessionStorage.removeItem("selectedCreator");
  };

  const storedSystemInstructions =
    sessionStorage.getItem("systemInstructions") || "";
  const storedTemperature = Number(sessionStorage.getItem("temperature")) || 1;
  const storedMaxTokens = Number(sessionStorage.getItem("maxTokens")) || 100;
  const storedTopP = Number(sessionStorage.getItem("topP")) || 1;
  const storedStopSequences = JSON.parse(
    sessionStorage.getItem("stopSequences") || "[]"
  );
  const storedFrequencyPenalty =
    Number(sessionStorage.getItem("frequencyPenalty")) || 0;
  const storedPresencePenalty =
    Number(sessionStorage.getItem("presencePenalty")) || 0;

  const storedSelectedModel = sessionStorage.getItem("selectedModel");
  const storedSelectedCreator = sessionStorage.getItem("selectedCreator");

  useEffect(() => {
    storedSystemInstructions && setSystemInstructions(storedSystemInstructions);
    storedTemperature && setTemperature(storedTemperature);
    storedMaxTokens && setMaxTokens(storedMaxTokens);
    storedTopP && setTopP(storedTopP);
    storedStopSequences && setStopSequences(storedStopSequences);
    storedFrequencyPenalty && setFrequencyPenalty(storedFrequencyPenalty);
    storedPresencePenalty && setPresencePenalty(storedPresencePenalty);
    storedSelectedModel && setSelectedModel(storedSelectedModel);
    storedSelectedCreator && setSelectedCreator(storedSelectedCreator);
  }, []);

  // const checkCreditsOfUserAfterSending = async () => {
  //   const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
  //   try {
  //     const response = await axios.get(`${urlEnv}checkcreditsaftersending`, {
  //       params: { user_id },
  //     });
  //     console.log("checkCreditsOfUser", response.data.total_credits);
  //     if (
  //       response.data.total_credits <= 1024 &&
  //       response.data.total_credits >= 1
  //     ) {
  //       sessionStorage.setItem(
  //         "maxTokensApplicable",
  //         JSON.stringify(response.data.total_credits)
  //       );
  //     } else {
  //       sessionStorage.removeItem("maxTokensApplicable");
  //     }
  //   } catch (err) {
  //     console.error(`error fetching checkCreditsOfUser: ${err.message}`);
  //   }
  // };

  const fetchAutoRecharge = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}fetch-auto-recharge`, {
        params: { user_id: user_id },
      });

      if (response) {
        //console.log("response.data fetchAutoRecharge:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("fetchAutoRecharge: ", error);
    }
  };

  const fetchCreditsConversion = async () => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}fetch-credits-conversion`);

      if (response.data) {
        //console.log("response.data fetchCreditsConversion:", response.data);
        return response.data.itemized_per_credits;
      } else {
        return null;
      }
    } catch (error) {
      console.error("fetchCreditsConversion error: ", error);
    }
  };

  const fetchCustomerId = async (pm: string) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}get-payment-method`, {
        params: { existingPaymentMethodId: pm },
      });
      if (response) {
        console.log("fetchCustomerId", response.data.paymentMethod.customer);
        return response.data.paymentMethod.customer;
      }
    } catch (error) {
      console.error("error get-payment-method:", error);
    }
  };

  const calculteAutoRecharge = async (response: any) => {
    //console.log("response:calculteAutoRecharge ", response);
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    if (response.data?.total_credits) {
      const autoRechargeData = await fetchAutoRecharge();
      // console.log("autoRechargeData: ", autoRechargeData);
      if (autoRechargeData) {
        // console.log("autoRechargeData: ", autoRechargeData);
        if (autoRechargeData.active === "false") {
          return;
        } else if (autoRechargeData.active === "true") {
          const creditsConversionData = await fetchCreditsConversion();
          // console.log(
          //   "autoRechargeData.threshold_amount",
          //   autoRechargeData.threshold_amount
          // );
          const exceededThreshold =
            response.data?.total_credits <=
            autoRechargeData.threshold_amount / creditsConversionData;

          if (exceededThreshold) {
            // trigger the auto recharge here!
            const pm = autoRechargeData.payment_method;
            const amount = autoRechargeData.recharge_amount;
            const customerId = await fetchCustomerId(pm);

            try {
              const response = await axios.post(
                `${urlEnv}create-checkout-session`,
                {
                  payment_method: pm,
                  customerId: customerId,
                  recharge_amount: amount,
                  user_id: user_id,
                }
              );
              response && console.log("AUTO RECHARGE CREDITS", response.data);
              if (response.data) {
                if (window.fbq) {
                  window.fbq("track", "Purchase", {
                    value: response.data.amount,
                    currency: "USD",
                  });
                }
              }
            } catch (error) {
              console.error("error triggering auto recharge:", error);
            }

            // console.log("customerId1231231321: ", customerId);
            // console.log(
            //   "autoRechargeDatapaymen_ethod",
            //   autoRechargeData.payment_method
            // );
            // console.log("userID", user_id);
            // console.log("amount", amount);
          } else {
            return;
          }
          //fetch calculation of credits, to trigger auto recharge
        }
      }
    }
  };

  const checkCreditsOfUser = async () => {
    console.log("ASDASDJHASJDASD");
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS;
    try {
      const response = await axios.get(`${urlEnv}checkCreditsOfUser`, {
        params: { user_id },
      });
      //console.log("checkCreditsOfUser", response);

      if (response.data) {
        //if subscribe
        //if subscribed
        if (response.data?.total_credits <= 1) {
          setIsSubscribe(false);
          setIsEmptyCredits(true);
        } else {
          setIsSubscribe(true);
          setIsEmptyCredits(false);
        }
        calculteAutoRecharge(response);
      }
      setLoadingIsSubscribe(false);
    } catch (err) {
      // console.log(`error fetching checkCreditsOfUser: ${err.message}`);
    }
  };
  useEffect(() => {
    setLoadingIsSubscribe(true);

    checkCreditsOfUser();
  }, [user_id]);

  const sendMessage = async () => {
    if (!session) {
      alert("signup and sign in to use panelsAI");
      return;
    }
    if (isEmptyCredits) {
      alert("Insufficient credits. would you like to refill your credits? ");
      return;
    } else if (!loadingIsSubscribe && !isSubscribe) {
      alert("Subscribe to use Panels'AI");
      return;
    }

    // console.log("User ID:", user_id);
    // console.log(chats);
    // console.log(selectedChatId);
    // console.log("InputTextInputTextInputTextInputText", inputText);

    if (inputText.trim() != "") {
      if (selectedModel != "Model" && selectedCreator != "Creator")
        startNewChat();
      // console.log("created new chat");
    } else {
      alert("Please input text");
      return;
    }

    //ensure chatID exist in welcomescreen
    // if (selectedChatId === null) {
    //   startNewChat();
    //   console.log(selectedChatId);
    //   return;
    // }

    if (selectedCreator == "OpenAI") {
      // console.log("selectedModel", selectedModel);
      if (selectedModel == "Model") {
        alert("Please select a Model");
      } else {
        await generateOpenAI();

        //console.log("model is openai");
      }
    } else if (selectedCreator == "Anthropic") {
      // console.log("selectedModel", selectedModel);
      if (selectedModel == "Model") {
        alert("Please select a Model");
      } else {
        //   console.log("model is anthropic");

        // alert("anthropic is still not working");
        await generateAnthropic();
      }
    } else {
      return;
    }
  };

  const [tokenLimitReach, setTokenLimitReach] = useState(false);
  // console.log("isEmptyCredits", isEmptyCredits);

  const generateOpenAI = async () => {
    setIsLoading(true);

    let toltaltoken = 0;

    const chat = chats.find((chat) => chat.id === selectedChatId);
    console.log("chat", chat);

    if (!chat) {
      // alert("Open a new chat");
      setIsLoading(false);

      return;
    }

    // if (isEmptyCredits) {

    //   alert(
    //     "Insufficient credits. would you like to refill your credits? "
    //   );
    //   return
    // }

    const limit = tokenLimits.find(
      (model) => model.model === selectedModel
    ).limit;

    const tokens = encode(inputText).length;
    if (tokens >= limit) {
      // console.log("limit", limit);
      // console.log("tokens", tokens);
      // console.log("exceeding the token limit ERROR");
      openDialog();
      setInputText("");
      sessionStorage.removeItem("inputText");
      setIsLoading(false);

      return;
    }
    const highestId = chat.messages.reduce(
      (maxId, message) => Math.max(maxId, message.id),
      0
    );

    const messageWithSystemInstuction: Message = {
      id: highestId + 1,
      text: systemInstructions,
      role: "system",
    };
    let newMessageWithSystemInstuction = [messageWithSystemInstuction];
    // updateChatMessages();

    const newMessage: Message = {
      id: messageWithSystemInstuction.id + 1,
      text: inputText,
      role: "user",
      isNew: true,
    };

    // let withSystemTOF = chat.messages[0]?.role === "system";
    // console.log();
    let updatedMessages = [
      ...chat.messages,
      // ...(systemInstructions.trim() === "" || withSystemTOF
      //   ? []
      //   : newMessageWithSystemInstuction),

      newMessage,
    ];
    console.log("hahahha1:", chat.messages);
    // console.log("yo", updatedMessages);

    const loaderMessage: Message = {
      id: newMessage.id + 1,
      text: "loading", //change this to loader
      role: "assistant",
      isNew: true,
    };

    let messagesWithLoader = [...updatedMessages, loaderMessage];
    console.log("hahahha2:", chat.messages);
    updateChatMessages(
      chat.id,
      messagesWithLoader,
      selectedCreator,
      selectedModel,
      systemInstructions,
      temperature,
      maxTokens,
      stopSequences,
      topP,
      frequencyPenalty,
      presencePenalty
    );

    console.time("Fetch Openai Response");
    try {
      // fetch the logs of threadID of user using chatID shit
      let messageContext = [];

      messageContext = await fetchChatThreadIDLogs(
        chat.id,
        user_id,
        inputText,
        tokenLimitReach
        // withSystemTOF
      );

      // console.log("messageContext before call", messageContext);

      // console.log("tokenLimitReach", tokenLimitReach);

      if (tokenLimitReach) {
        const userLastMessage = chat.messages[chat.messages.length - 2];
        const aiLastMessage = chat.messages[chat.messages.length - 1];

        chat.messages = [userLastMessage, aiLastMessage];
        // console.log(
        //   "chat.messageschat.messages",
        //   chat.messages[chat.messages.length - 1]
        // );
        // console.log("chat.messageschat.messages", chat);

        updatedMessages = [...chat.messages, newMessage];
        // console.log("updatedMessagesupdatedMessages", updatedMessages);

        messagesWithLoader = [...updatedMessages, loaderMessage];

        updateChatMessages(
          chat.id,
          messagesWithLoader,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        );

        localStorage.setItem("chats", JSON.stringify(chats));

        //console.log("madapaking chats", chats);
      }

      // console.log("temperature", temperature);
      // console.log("maxTokens", maxTokens);
      // console.log("topP", topP);
      // console.log("systemInstructions", systemInstructions);
      // console.log("stopSequences", stopSequences);
      // console.log("frequencyPenalty", frequencyPenalty);
      // console.log("presencePenalty", presencePenalty);

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: selectedModel,
          messages: messageContext,
          temperature: temperature,
          max_tokens: maxTokens,
          top_p: topP,
          stop: stopSequences.length > 0 ? stopSequences : undefined,
          frequency_penalty: frequencyPenalty,
          presence_penalty: presencePenalty,
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("hahahha:", response.data.usage);
      //console.log("temperatureyoyoyo", response);
      const botMessage: Message = {
        id: loaderMessage.id,
        text: response.data.choices[0].message.content,
        role: "assistant",
        isNew: true,
      };
      const finalMessages = [...updatedMessages, botMessage];
      console.timeEnd("Fetch Openai Response");
      updateChatMessages(
        chat.id,
        finalMessages,
        selectedCreator,
        selectedModel,
        systemInstructions,
        temperature,
        maxTokens,
        stopSequences,
        topP,
        frequencyPenalty,
        presencePenalty
      );
      // console.log("finalMessages", finalMessages);
      setIsLoading(false);
      sessionStorage.removeItem("inputText");

      const completion_tokens = response.data.usage.completion_tokens;
      const prompt_tokens = response.data.usage.prompt_tokens;
      const total_token = completion_tokens + prompt_tokens;

      //console.log("completion_tokens", completion_tokens);
      //reset the history logs
      if (total_token >= limit - 3000) {
        setTokenLimitReach(!tokenLimitReach);
      }

      const updateUserTokenPromise = axios
        .post(`${process.env.REACT_APP_SERVER_ACCESS}update-user-credits`, {
          // total_tokens: total_tokens,
          chat_id: chat.id,
          completion_tokens: completion_tokens,
          prompt_tokens: prompt_tokens,
          user_id: user_id,
          selectedModel: selectedModel,
          selectedCreator: selectedCreator,
          total_token_used: total_token,
        })
        .catch((error) => {
          // console.log("Error updating user token:", error);
        });

      const saveQtoDBPromise = saveQtoDB(
        finalMessages,
        chat.id,
        total_token,
        completion_tokens,
        prompt_tokens,
        model_id,
        tokenLimitReach
      );

      // const saveToDBAdvancedSetting = axios.post(
      //   `${process.env.REACT_APP_SERVER_ACCESS}save-advanced`,
      //   {
      //     instruction: systemInstructions,
      //     temperature: temperature,
      //     max_tokens: maxTokens,
      //     top_p: topP,
      //     stop_sequence: stopSequences.length > 0 ? stopSequences : null,
      //     frequency_penalty: frequencyPenalty,
      //     presence_penalty: presencePenalty,
      //   }
      // );

      await Promise.all([
        saveQtoDBPromise,
        updateUserTokenPromise,
        removeAllItems,
      ]);
      checkCreditsOfUser();

      // console.log("chatyoyoyo", chat);
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
      sessionStorage.removeItem("inputText");
    }
  };

  const fetchChatThreadIDLogs = async (
    chat_id,
    user_id,
    inputText,
    tokenLimitReach,
    withSystemTOF?
  ) => {
    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "getThreadId";

    const response = await axios.get(urlEnv, {
      params: {
        chat_id: chat_id,
        user_id: user_id,
      },
    });
    let newMessage = [];
    let messageContext = [];
    if (systemInstructions && !withSystemTOF) {
      messageContext.push({ role: "system", content: systemInstructions });
    }
    if (response.data) {
      // Format past messages
      messageContext = response.data.messages.map((msg) => ({
        // role: msg.role === "user" ? "user" : "assistant",
        role:
          msg.role === "user"
            ? "user"
            : msg.role === "assistant"
            ? "assistant"
            : msg.role === "system"
            ? "system"
            : "unknown",
        content: msg.text,
      }));
    }

    console.log("messageContext", messageContext);

    if (tokenLimitReach) {
      setTokenLimitReach(false);
      messageContext = messageContext.slice(-2);
      // sessionStorage.setItem("chats", JSON.stringify(messageContext));
      alert("conversation history UPDATED!");
    }

    // Add current input text to the context
    messageContext.push({ role: "user", content: inputText });
    setInputText("");
    //("yoyoyoyooyoyoyoyoyoyoyo", messageContext);
    return messageContext;
  };
  const generateAnthropic = async () => {
    setIsLoading(true);
    let toltaltoken = 0;
    const chat = chats.find((chat) => chat.id === selectedChatId);

    if (!chat) {
      setIsLoading(false);
      return; // Exit early if no chat is selected
    }

    const limit = tokenLimits.find(
      (model) => model.model === selectedModel
    ).limit;

    const tokens = encode(inputText).length;
    if (tokens >= limit) {
      // console.log("limit", limit);
      // console.log("tokens", tokens);
      // console.log("exceeding the token limit ERROR");
      openDialog();
      setInputText("");
      sessionStorage.removeItem("inputText");
      setIsLoading(false);

      return;
    }
    const highestId = chat.messages.reduce(
      (maxId, message) => Math.max(maxId, message.id),
      0
    );

    // const messageWithSystemInstuction: Message = {
    //   id: highestId + 1,
    //   text: systemInstructions,
    //   role: "assistant",
    // };
    // let newMessageWithSystemInstuction = [messageWithSystemInstuction];
    // // updateChatMessages();

    const newMessage: Message = {
      id: highestId + 1,
      text: inputText,
      role: "user",
      isNew: true,
    };

    let withSystemTOF = chat.messages[0]?.role === "system";

    let updatedMessages = [
      ...chat.messages,
      // ...(withSystemTOF ? [] : newMessageWithSystemInstuction),
      newMessage,
    ];

    const loaderMessage: Message = {
      id: newMessage.id + 1,
      text: "loading", // You will replace this with the loader component in your UI
      role: "assistant",
      isNew: true,
    };

    let messagesWithLoader = [...updatedMessages, loaderMessage];
    updateChatMessages(
      chat.id,
      messagesWithLoader,
      selectedCreator,
      selectedModel,
      systemInstructions,
      temperature,
      maxTokens,
      stopSequences,
      topP,
      frequencyPenalty,
      presencePenalty
    );

    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "callanthropic";

    if (tokenLimitReach) {
      const userLastMessage = chat.messages[chat.messages.length - 2];
      const aiLastMessage = chat.messages[chat.messages.length - 1];

      chat.messages = [userLastMessage, aiLastMessage];
      // console.log(
      //   "chat.messageschat.messages",
      //   chat.messages[chat.messages.length - 1]
      // );
      // console.log("chat.messageschat.messages", chat);

      updatedMessages = [...chat.messages, newMessage];
      //console.log("updatedMessagesupdatedMessages", updatedMessages);

      messagesWithLoader = [...updatedMessages, loaderMessage];

      updateChatMessages(
        chat.id,
        messagesWithLoader,
        selectedCreator,
        selectedModel,
        systemInstructions,
        temperature,
        maxTokens,
        stopSequences,
        topP,
        frequencyPenalty,
        presencePenalty
      );
      setIsLoading(false);

      localStorage.setItem("chats", JSON.stringify(chats));

      //console.log("madapaking chats", chats);
    }

    try {
      // Fetch logs and call the API in parallel
      const [response] = await Promise.all([
        axios.post(
          urlEnv,
          {
            model: selectedModel,
            system: systemInstructions,
            temperature: temperature,
            max_tokens: maxTokens,
            stop_sequences:
              stopSequences.length > 0 ? stopSequences : undefined,
            messages: await fetchChatThreadIDLogs(
              chat.id,
              user_id,
              inputText,
              tokenLimitReach,
              (withSystemTOF = true) // must because integration of system instruction on anthropic is dif with openai
            ),
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_ANTHROPIC_API_KEY,
              "Content-Type": "application/json",
            },
          }
        ),
      ]);

      ///console.log("responseyoyoyo", response);

      const botMessage: Message = {
        id: loaderMessage.id,
        text: response.data.content[0].text,
        role: "assistant",
        isNew: true,
      };

      const finalMessages = [...updatedMessages, botMessage];

      setIsLoading(false);
      sessionStorage.removeItem("inputText");

      const completion_tokens = response.data.usage.output_tokens;
      const prompt_tokens = response.data.usage.input_tokens;
      const total_token = completion_tokens + prompt_tokens;

      if (total_token >= limit - 3000) {
        setTokenLimitReach(!tokenLimitReach);
      }

      const updateUserTokenPromise = axios
        .post(`${process.env.REACT_APP_SERVER_ACCESS}update-user-credits`, {
          // total_tokens: total_tokens,
          chat_id: chat.id,
          completion_tokens: completion_tokens,
          prompt_tokens: prompt_tokens,
          user_id: user_id,
          selectedModel: selectedModel,
          selectedCreator: selectedCreator,
          total_token_used: total_token,
        })
        .catch((error) => {
          //console.log("Error updating user token:", error);
        });

      const saveQtoDBPromise = saveQtoDB(
        finalMessages,
        chat.id,
        total_token,
        completion_tokens,
        prompt_tokens,
        model_id,
        tokenLimitReach
      );

      // Parallel execution of update and other operations
      await Promise.all([
        updateChatMessages(
          chat.id,
          finalMessages,
          selectedCreator,
          selectedModel,
          systemInstructions,
          temperature,
          maxTokens,
          stopSequences,
          topP,
          frequencyPenalty,
          presencePenalty
        ),
        updateUserTokenPromise,
        saveQtoDBPromise,
        removeAllItems,
      ]);
      checkCreditsOfUser();
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      sessionStorage.removeItem("inputText");
    }
  };

  const saveQtoDB = async (
    finalMessages,
    chatID,
    total_token_used,
    completion_tokens,
    prompt_tokens,
    model_id,
    tokenLimitReach
  ) => {
    console.log("finalMessages", finalMessages);

    const urlEnv = process.env.REACT_APP_SERVER_ACCESS + "saveThread";
    try {
      const response = await axios.post(urlEnv, {
        log: finalMessages,
        user_id: user_id,
        model_id: model_id,
        chat_id: chatID,
        total_token_used: total_token_used,
        completion_tokens: completion_tokens,
        prompt_tokens: prompt_tokens,
        selectedCreator: selectedCreator,
        selectedModel: selectedModel,
        tokenLimitReach: tokenLimitReach,

        instruction: systemInstructions,
        temperature: temperature,
        max_tokens: maxTokens,
        top_p: topP,
        stop_sequence: stopSequences.length > 0 ? stopSequences : null,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty,
      });
    } catch (error) {
      if (error.response) {
        console.error("Error saving query:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line with Shift + Enter
        return;
      } else {
        // Prevent default Enter behavior and send message
        e.preventDefault();
        sendMessage();
      }
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset height to auto to shrink if necessary
    textarea.style.height = `${textarea.scrollHeight + 1}px`; // Set height to scroll height
  }, [inputText]);

  return (
    <div className="flex-div mb-10">
      <div className="input-container">
        <textarea
          ref={textareaRef}
          placeholder="Ask Panels something"
          name="inputText"
          value={inputText}
          onKeyDown={handleKeyDown}
          onChange={handleSettingsChange}
          disabled={isLoading}
          rows={1}
        />
        <SendButton
          ref={sendMessageButtonRef}
          onClick={sendMessage}
          disabled={isLoading}
        />
      </div>
      <Dialog.Root
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
        }}
      >
        <Dialog.Trigger asChild></Dialog.Trigger>
        <Dialog.Portal>
          <div className="overlay"></div>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent DialogCustomWidth-signup">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <Dialog.Description className="DialogDescription"></Dialog.Description>

            <div className="subscription-panel">
              <h2>Error generating response</h2>
              <p className="description">
                The message you are trying to submit is too long. Please submit
                something shorter.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 25,
                justifyContent: "flex-end",
              }}
            >
              <Dialog.Close asChild></Dialog.Close>
            </div>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close">
                <img
                  src={crossicon}
                  alt="icon"
                  style={{
                    width: "20px",
                    height: "20px",

                    filter: "invert(1)",
                  }}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
export default InputText;
